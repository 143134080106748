input[type="text"] {
    background: transparent;
    border-bottom: 1px solid $black;
    &::placeholder {
        color: $black;
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/*input[type="email"], input[type="text"], input[type="phone"], input[type="search"], input[type="password"] {
	width: 100%;
	padding: 20px 20px 18px 20px;
	border: 1px solid darken($lightgrey, 10%);
	background-color: $white;
	color: $black;

  @include xs {
    padding: 16px;
    font-size: 13px;
  }
} 

input[type="email"]:focus, input[type="text"]:focus, input[type="phone"]:focus, input[type="search"]:focus, input[type="password"]:focus {
	outline: 0;
	border: 1px solid lighten($blue, 10%);
} 
input[type="submit"] {
	@extend .button;
}


input[type="checkbox"] + label:hover {
	cursor: pointer;
}


.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }


input[type="checkbox"],
input[type="radio"] {
  @include vh();
  &:focus {
    + label {
      &:before {
      }
    }
  }
  + label {
    position: relative;
    padding: 1px 0px 0px 25px;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    display: inline-block;
    user-select: none;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $lightgrey;
      background: $white;
      text-align: center;
      transition: background 200ms ease-out;
    }
    &:after {
      content: "";
      position: absolute;
      transform: scale(0);
      transition: transform 200ms ease-out;
    }
  }
}

input[type="checkbox"] {
  + label {
    &:after {
		background-color: transparent;
		content: "";
		display: block;
		position: absolute;
		left: 6px;
		top: 1px;
		width: 5px;
		height: 11px;
		opacity: 0.2;
		border-bottom: 3px solid $blue;
		border-right: 3px solid $blue;
		transform: rotate(45deg);
		transition: border-color 0.3s ease;
    }
  }
  &:checked {
    + label {
      &:before {
        content: "";
      }

      &:after {
        content: "";
        opacity: 1;
      }
    }
  }
}*/