nav {
	position: relative;
	display: block;
	top: 0;
	width: 100%;
	z-index: 10;
	.nav-wrapper {
		max-width: 1440px;
		margin: 0 auto;
		position: relative;
		> a {
			.logo {
				display: inline-block;
				position: relative;
				left: -1.9vw;
				margin: 4vw 0;
				margin-bottom: 5vw;
				max-width: 200px;
				transition: margin-bottom 1s ease;
				path {
					fill: #343466;
				}
				&.open-menu {
					margin-bottom: 150px
				}
				@media (max-width: 1580px) {
					left: 0;
				}
				@media (max-width: 1480px) {
					left: 30px;
				}
			}
			@media (max-width: 830px) {
				.logo {
					left: 50%;
					transform: translateX(-50%);
					margin: 60px 0 40px;
				}
			}
		}
	}
	> ul {
		position: absolute;
		top: 25px;
		right: 30px;
		text-align: right;
		li {
			margin: 10px 0;
			a {
				text-transform: uppercase;
				letter-spacing: 0.2em;
				color: $white;
				text-decoration: none;
				span {
					border-bottom: 1px solid $white;
				}
			}
			&:last-child {
				a {
					border-bottom: 1px solid $white;
				}
			}
			ul {
				display: block;
				overflow: hidden;
				height: 0;
				width: 100%;
				max-width: 650px;
				font-weight: 700;
				max-height: 0;
				transition: max-height 2s ease;
				li {
					font-size: 16px;
					margin: 5px 0;
					&:last-child {
						a {
							border-bottom: 0px
						}
					}
					a {
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
			&.open {
				ul {
					margin: 20px 0;
					display: block;
					height: auto;
					max-height: 1000px;
				}
				.plus-minus {
					line:last-of-type {
						display: none;
					}
				}
			}
			.plus-minus {
				display: inline-block;
				position: relative;
				top: 1px;
				width: 13px;
				height: 13px;
				line {
					stroke: $white;
    				stroke-width: 13px;
				}
			}
		}
	}
	.mobile-menu {
		display: none;
		@media (max-width: 830px) {
			display: block;
			position: absolute;
			top: 20px;
			right: 20px;
			width: 30px;
			span {
				display: block;
				border-top: 2px solid $white;
				margin-bottom: 6px;
			}
			&.open {
				top:28px;
				right: 18px;
				span:nth-of-type(1) {
					transform: rotate(45deg);
					transform-origin: 50% 50%;
					margin: 0;
				}
				span:nth-of-type(2) {
					opacity: 0;
				}
				span:nth-of-type(3) {
					transform: rotate(-45deg) translateY(3px) translateX(2px);
					margin: 0;
					transform-origin: 0% 0%;
				}
			}
		}
	}
	@media (max-width: 830px) {
		ul {
			display: none;
		}
		
		&.open {
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background-color: $blue;
			.logo {
				path {
					fill: $white !important;
				}
			}
			> ul {
				display: block;
				width: 100%;
				position: absolute;
				height: auto;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				text-align: center;
				li {
					a {
						color: $white;
						font-size: 18px;
					}
					ul {
						display: block;
						text-align: center;
						height: auto;
						max-height: 1000px;
						margin-top: 20px;
						li {
							max-width: 80%;
							margin: 0 auto;
							margin-bottom: 20px;
							&:last-child {
								margin-bottom: 30px;
							}
							a {
								font-size: 13px;
							}
						}
					}
				}
				.plus-minus {
					display: none;
				}
			}
		}
	}
	@media (max-height: 480px) {
		&.open {
			> ul {
				top: 58%;
				li {
					ul {

						li {
							margin-bottom: 10px;
							&:last-child {
								margin-bottom: 20px;
							}
						}
					}
				}
			}
		}
	}
}
