// Media Query Breakpoints
$xs: 414px;
$sm: 768px;
$md: 1024px;
$lg: 1440px;
$xl: 1600px;
$super: 2000px;

@mixin iphone4 {
    @media (max-width: $xs) and (max-height: 480px) and (orientation: portrait) {
        @content;
    }
}
@mixin iphone5 {
    @media (max-width: 320px) and (max-height: 568px) and (orientation: portrait) {
        @content;
    }
}
@mixin iphone6-8 {
    @media (max-width: 375px) and (max-height: 667px) and (orientation: portrait) {
        @content;
    }
}
@mixin iphone6-8plus {
    @media (max-width: 414px) and (max-height: 736px) and (orientation: portrait) {
        @content;
    }
}
@mixin iphoneX {
    @media (max-width: 375px) and (max-height: 812px) and (orientation: portrait) {
        @content;
    }
}
@mixin xs {
    @media (max-width: $xs) {
        @content;
    }
}
@mixin sm {
    @media only screen and (max-width: $sm) {
        @content;
    }
}
@mixin ipad-portrait {
    @media only screen and (max-width: $sm) and (min-height: $md) {
        @content;
    }
}
@mixin sm-p {
    @media only screen and (min-width: $sm) and (max-width: $sm) and (orientation: portrait) {
        @content;
    }
}
@mixin smallmob {
    @media only screen and (max-width: $sm) and (max-height: 750px) and (min-height: 600px) {
        @content;
    }
}
@mixin md {
    @media only screen and (max-width: $md) {
        @content;
    }
}
@mixin md-lg {
    @media only screen and (min-width: 769px) and (max-width: $md) {
        @content;
    }
}
@mixin lg {
    @media only screen and (max-width: $lg) {
        @content;
    }
}
@mixin xl {
    @media only screen and (max-width: $xl) {
        @content;
    }
}
@mixin super {
    @media only screen and (max-width: $super) {
        @content;
    }
}

@mixin from($from, $to) {
    @media only screen and (min-width: $from) and (max-width: $to) {
        @content;
    }
}

@mixin only($size) {
    @if $size == $phone {
        @media only screen and (max-width: $phone) {
            @content;
        }
    } @else if $size == $tablet {
        @media only screen and (min-width: $phone) and (max-width: $laptop - 1px) {
            @content;
        }
    } @else if $size == $laptop {
        @media only screen and (min-width: $laptop) and (max-width: $desktop - 1px) {
            @content;
        }
    } @else if $size == $desktop {
        @media only screen and (min-width: $desktop) and (max-width: $super - 1px) {
            @content;
        }
    } @else if $size == $super {
        @media only screen and (min-width: $super) {
            @content;
        }
    }
}

@mixin vh() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


@mixin wrapperFluid($min-vw, $max-vw, $min-value, $max-value) {
    max-width: calc(100% - #{$min-value});

    @media (min-width: $min-vw) {
        max-width: calc(100% - (calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})));
    }

    @media (min-width: $max-vw) {
        max-width: calc(100% - #{$max-value});
    }
}



@mixin gutterFluid($min-vw, $max-vw, $min-value, $max-value) {
    width: calc(25% - #{$min-value});

    @media (min-width: $min-vw) {
        width: calc(25% - (calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})));
    }

    @media (min-width: $max-vw) {
        width: calc(25% - #{$max-value});
    }
}



@mixin inter($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}




@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin ip(
    $properties,
    $min-screen,
    $min-value,
    $max-screen,
    $max-value,
    $easing: "linear",
    $bending-points: 2
) {
    // Default Easing 'Linear'
    $p0: 0;
    $p1: 0;
    $p2: 1;
    $p3: 1;

    // Parse Cubic Bezier string
    @if (str-slice($easing, 1, 12) == "cubic-bezier") {
        // Get the values between the brackets
        // TODO: Deal with different whitespace
        $i: str-index($easing, ")"); // Get index of closing bracket
        $values: str-slice(
            $easing,
            14,
            $i - 1
        ); // Extract values between brackts
        $list: explode($values, ", "); // Split the values into a list

        // @debug($list);

        // Cast values to numebrs
        $p0: number(nth($list, 1));
        $p1: number(nth($list, 2));
        $p2: number(nth($list, 3));
        $p3: number(nth($list, 4));
    }

    @if ($easing == "ease") {
        $p0: 0.25;
        $p1: 1;
        $p2: 0.25;
        $p3: 1;
    }

    @if ($easing == "ease-in-out") {
        $p0: 0.42;
        $p1: 0;
        $p2: 0.58;
        $p3: 1;
    }

    @if ($easing == "ease-in") {
        $p0: 0.42;
        $p1: 0;
        $p2: 1;
        $p3: 1;
    }

    @if ($easing == "ease-out") {
        $p0: 0;
        $p1: 0;
        $p2: 0.58;
        $p3: 1;
    }

    @each $property in $properties {
        #{$property}: $min-value;
    }

    @if ($easing == "linear" or $bending-points < 1) {
        @media screen and (min-width: $min-screen) {
            @each $property in $properties {
                #{$property}: calc-interpolation(
                    $min-screen,
                    $min-value,
                    $max-screen,
                    $max-value
                );
            }
        }
    } @else {
        // Loop through bending points
        $t: 1 / ($bending-points + 1);
        $i: 1;
        $prev-screen: $min-screen;
        $prev-value: $min-value;

        @while $t * $i <= 1 {
            $bending-point: $t * $i;
            $value: cubic-bezier-func($p0, $p1, $p2, $p3, $bending-point);
            $screen-int: lerp($min-screen, $max-screen, $bending-point);
            $value-int: lerp($min-value, $max-value, $value);

            @media screen and (min-width: $prev-screen) {
                @each $property in $properties {
                    #{$property}: calc-interpolation(
                        $prev-screen,
                        $prev-value,
                        $screen-int,
                        $value-int
                    );
                }
            }

            $prev-screen: $screen-int;
            $prev-value: $value-int;
            $i: $i + 1;
        }
    }

    @media screen and (min-width: $max-screen) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}



@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
    $a: ($max-value - $min-value) / ($max-screen - $min-screen);
    $b: $min-value - $a * $min-screen;

    $sign: "+";
    @if ($b < 0) {
        $sign: "-";
        $b: abs($b);
    }
    @return calc(#{$a * 100}vw #{$sign} #{$b});
}



@function solve-bexier-x($p1x, $p1y, $p2x, $p2y, $x) {
    $cx: 3 * $p1x;
    $bx: 3 * ($p2x - $p1x) - $cx;
    $ax: 1 - $cx -$bx;

    $t0: 0;
    $t1: 1;
    $t2: $x;
    $x2: 0;
    $res: 1000;

    @while ($t0 < $t1 or $break) {
        $x2: (($ax * $t2 + $bx) * $t2 + $cx) * $t2;
        @if (abs($x2 - $x) < $res) {
            @return $t2;
        }
        @if ($x > $x2) {
            $t0: $t2;
        } @else {
            $t1: $t2;
        }
        $t2: ($t1 - $t0) * 0.5 + $t0;
    }

    @return $t2;
}

@function cubic-bezier-func($p1x, $p1y, $p2x, $p2y, $x) {
    $cy: 3 * $p1y;
    $by: 3 * ($p2y - $p1y) - $cy;
    $ay: 1 - $cy - $by;
    $t: solve-bexier-x($p1x, $p1y, $p2x, $p2y, $x);
    @return (($ay * $t + $by) * $t + $cy) * $t;
}

@function lerp($a, $b, $t) {
    @return $a + ($b - $a) * $t;
}

@function number($value) {
    @if type-of($value) == "number" {
        @return $value;
    } @else if type-of($value) != "string" {
        $_: log("Value for `to-number` should be a number or a string.");
    }

    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == "-";
    $numbers: (
        "0": 0,
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6,
        "7": 7,
        "8": 8,
        "9": 9
    );

    @for $i from if($minus, 2, 1) through str-length($value) {
        $character: str-slice($value, $i, $i);

        @if not(index(map-keys($numbers), $character) or $character == ".") {
            @return to-length(
                if($minus, -$result, $result),
                str-slice($value, $i)
            );
        }

        @if $character == "." {
            $digits: 1;
        } @else if $digits == 0 {
            $result: $result * 10 + map-get($numbers, $character);
        } @else {
            $digits: $digits * 10;
            $result: $result + map-get($numbers, $character) / $digits;
        }
    }

    @return if($minus, -$result, $result);
}

@function explode($string, $delimiter) {
    $result: ();
    @if $delimiter == "" {
        @for $i from 1 through str-length($string) {
            $result: append($result, str-slice($string, $i, $i));
        }
        @return $result;
    }
    $exploding: true;
    @while $exploding {
        $d-index: str-index($string, $delimiter);
        @if $d-index {
            @if $d-index > 1 {
                $result: append($result, str-slice($string, 1, $d-index - 1));
                $string: str-slice($string, $d-index + str-length($delimiter));
            } @else if $d-index == 1 {
                $string: str-slice(
                    $string,
                    1,
                    $d-index + str-length($delimiter)
                );
            } @else {
                $result: append($result, $string);
                $exploding: false;
            }
        } @else {
            $result: append($result, $string);
            $exploding: false;
        }
    }
    @return $result;
}


@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Examples of different interpolation types
// References
// https://codepen.io/MadeByMike/pen/vNrvdZ
// will add more when I find them…


/*.classic-linear {
    @include ip("font-size", 600px, 12px, 900px, 24px);
}
.easy-peasy {
    @include ip("font-size", 600px, 12px, 900px, 24px, "ease-in");
}
.cubic-bezier {
    @include ip(
        "font-size",
        600px,
        12px,
        900px,
        24px,
        "cubic-bezier(0.755, 0.05, 0.855, 0.06)"
    );
}
.bloat-my-css {
    @include ip("font-size", 600px, 12px, 900px, 24px, "ease-in-ease-out", 6);
}*/
