@import "fonts.scss";

// custom webfonts
$custom1: "proxima-nova";
$custom2: "";

//sans-serif / system font
$primaryfont: #{$custom1}, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji";


body,p,li {
    font-family: $primaryfont;
    font-size: 18px;
    line-height: 1.4;
}


h1,h2,h3,h4,h5,h6 {
    font-family: $primaryfont;
}

strong {
    font-weight: 700;
}

em {
    font-style: italic;
}

strike {
    text-decoration: line-through;
}


.small-caps {
    font-family: $primaryfont;
	text-transform: uppercase;
    font-weight: 700;
   	letter-spacing: 0.2em;
}

/*
::-moz-selection {
    background: lighten($primary, 10%);
}
::selection {
    background: lighten($primary, 10%);
}*/



/*.link-hover {
    transition: background-position 0.2s ease;
    background-image: linear-gradient(to right, $black 0% , $black 50%, transparent 50.1%, transparent 98%) !important;
    background-repeat: repeat-x;
    background-position: 100% 100%;
    background-size: 250% 0.1em;
}
.link-hover:hover {
    background-position: 18% 100%;
}

.link-hover-full {
    transition: background-position 0.2s ease;
    background-image: linear-gradient(to right, $black 0% , $black 50%, transparent 50.1%, transparent 98%) !important;
    background-repeat: repeat-x;
    background-position: 100% 100%;
    background-size: 250% 0.05em;
}
.link-hover-full:hover {
    background-position: 0% 100%;
}


.link-hover-white {
    transition: background-position 0.2s ease;
    background-image: linear-gradient(to right, $white 0% , $white 50%, transparent 50.1%, transparent 98%) !important;
    background-repeat: repeat-x;
    background-position: 100% 100%;
    background-size: 250% 0.05em;
}
.link-hover-white:hover {
    background-position: 18% 100%;
}

.link-hover-white-full {
    transition: background-position 0.2s ease;
    background-image: linear-gradient(to right, $white 0% , $white 50%, transparent 50.1%, transparent 99.9%) !important;
    background-repeat: repeat-x;
    background-position: 100% 100%;
    background-size: 250% 0.05em;
}
.link-hover-white-full:hover {
    background-position: 0% 100%;
}*/

