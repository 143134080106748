@import "utils/mixins.scss";
@import "utils/variables.scss";

@import "base/fonts.scss";
@import "base/reset.scss";
@import "base/typography.scss";
@import "components/articles.scss";
@import "components/btn.scss";
@import "components/forms.scss";
@import "components/nav.scss";


@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

html {
	height: auto;
	min-height: 100%;
}

body {
	min-height: 100vh;
	padding-bottom: 99px;
	position: relative;
	-webkit-font-smoothing: antialiased;
	font-family: $primaryfont;
	background-color: #B9B9B9;
	color: $black;
	font-variant-numeric: oldstyle-nums;
	&.lock {
		overflow: hidden;
	}
	@media (max-width: 830px) {
		padding-bottom: 0
	}
	@media (max-height: 870px) {
		padding-bottom: 0
	}
}

main {
	display: block;
	position: relative;
	overflow-x: hidden;
	@media (max-width: 680px) {
		
	}
	&.home {
		h1 {
			position: absolute;
			width: 80%;
			left: 0;
			bottom: 0px;
			font-size: 28px;
			@include ip('font-size', 820px, 17px, 1240px, 28px, 'linear');
			@include ip('padding', 820px, 30px, 1240px, 70px, 'linear');
			color: $white;
			letter-spacing: -0.05em;
			background-color: $bluetrans;
			a {
				text-decoration: underline;
			}
			@media (max-width: 960px) {
				//width: 100%;
			}
		}
	}
	.slider-wrapper {
		width: calc(100% - 40px);
		max-width: 1400px;
		margin: 0 auto;
		position: relative;
		@media (max-width: 830px) {
			width: 100%;
		}
		.image-slider {
			img {
				display: block !important;
				height: 65vh;
				width: 100%;
				object-fit: cover;
			}
			margin-bottom: 20px;
			@media (max-width: 1290px) {
				margin-bottom: 50px;
			}
		}
		.slider-nav {
			position: absolute;
			bottom: 16px;
			z-index: 10;
			right: -16px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@media (max-width: 1540px) {
				right: 25px;
			}
			@media (max-width: 1300px) {
				right: 46px;
				bottom: -20px;
			}
		}
		.slick-arrow {
			position: absolute;
			padding: 13px 10px 15px 10px;
			height: 44px;
			width: 44px;
			background-color: $white;
			text-align: center;
			display: block;
			svg {
				max-width: 100%;
				max-height: 100%;
				overflow: visible;
				max-width: 25px;
				height: 15px;
			}
			&.slick-prev {
				margin-right: 2px;
				padding-left: 8px;
				left: -48px;
				zoom: 0.6;
				path {
					stroke: $black;
					stroke-width: 30px;
				}
			}
			&.slick-next {
				padding-right: 8px;
				zoom: 0.6;
				path {
					stroke: $black;
					stroke-width: 30px;
				}
			}
			@media (max-width: 830px) {
				svg {
					max-width: 7px;
				}
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
	&.page {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		> img {
			width: 50%;
			min-height: calc(100vh - 100px - 53px - 5vw);
			max-height: 90vh;
			object-fit: cover;
			object-position: 50% 50%;
			&.contact {
				object-position: 50% 100%;
			}
		}
		> article {
			width: 50%;
			position: relative;
			background-color: $white;
			min-height: 500px;
			.article-wrapper {
				position: absolute;
				left: 15%;
				padding-right: 7%;
				top: 50%;
				transform: translateY(-50%);
				max-width: 550px;
				h1 {
					font-size: 32px;
					text-transform: uppercase;
					font-weight: 300;
					line-height: 1.2;
					color: #333366;
					margin-bottom: 20px;
					&.contact {
						text-transform: none;
						a {
							display: inline-block;
							line-height: 1;
							border-bottom: 2px solid $blue;
						}
						@media (max-width: 380px) {
							font-size: 22px;
						}
					}
				}
				p {
					color: #333366;
					margin-bottom: 20px;
					&:last-child {
						margin-bottom: 0;
					}
					a {
						text-decoration: underline;
					}
					&.small {
						font-size: 15px;
						@media (max-width: 380px) {
							font-size: 13px;
						}
					}
				}
			}
		}
		@media (max-width: 830px) {
			background-color: $white;
			> img {
				position: relative;
				width: 100%;
				min-height: initial;
				max-height: 50vh;
				left: -20%;
				&.contact {
					max-height: 400px;
					left: initial;
					right: -20%;
					object-position: 100% 100%;
				}
			}
			> article {
				width: 100%;
				min-height: 200px;
				.article-wrapper {
					position: relative;
					left: initial;
					width: calc(100% - 100px);
					padding: 50px 0;
					top: initial;
					transform: translateX(0);
					margin: 0 auto;
					h1 {
						font-size: 28px;
					}
					p {
						font-size: 14px;
					}
				}
			}
		}
		@media (max-height: 870px) and (max-width: 830px) {
			background-color: $white;
			> img {
				position: relative;
				width: 100%;
				min-height: initial;
				left: -20%;
			}
			> article {
				width: 100%;
				min-height: 200px;
				.article-wrapper {
					position: relative;
					left: initial;
					width: calc(100% - 100px);
					padding: 50px 0;
					margin: 0 auto;
					h1 {
						font-size: 28px;
					}
					p {
						font-size: 14px;
					}
				}
			}
		}
		&.contact-page {
			@media (max-width: 830px) {
				flex-direction: column-reverse;
				padding-bottom: 40px;
			}
		}
	}
}

footer {
	position: relative;
	width: 100%;
	bottom: 0;
	padding-bottom: 37px;
	padding-left: 30px;
	padding-top: 30px;
	.footer-wrapper {
		max-width: 1440px;
		left: -1.9vw;
		margin: 0 auto;
		position: relative;
		p {
			font-size: 12px;
			opacity: 0.7;
			@media (max-width: 630px) {
				br {
					display: none;
				}
			}
		}
		@media (max-width: 1540px) {
			left: 0;
		}
	}
	&.footer-page {
		background-color: #333366;
		p {
			color: $white;
		}
		
	}
	@media (max-width: 830px) {
		position: relative;
		padding-right: 30px;
		.footer-wrapper {
			width: 100%;
			left: 0;
			p {
				font-size: 11px;
				width: 100%;
			}
		}

	}
}

.wrapper {
    max-width: calc(100% - 60px);
    margin: 0 auto;
    position: relative;
    @include wrapperFluid(680px, 1920px, 30px, 60px);
}


.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	&.visible {
		display: block;
		z-index: 1;
	}
	.center {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 600px;
		transform: translateX(-50%) translateY(-50%);
		&.search-form {
			max-width: calc(100% - 40px);
			margin: 0 auto;
			width: 500px;
		}
	}
	.close {
		color: $white;
		position: absolute;
		top: 30px;
		right: 20px;
		line-height: 0;
		font-size: 32px;
		cursor: pointer;
	}

} 





