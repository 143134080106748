* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    text-decoration: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

a {
    color: inherit;
    background-color: transparent;
}

img {
    max-width: 100%;
    height: auto;
    opacity: 1;
    border-style: none;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
    &:before,
    &:after {
        content: "";
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%; 
  line-height: 1;
  margin: 0;
}

pre {
    white-space: pre; 
    white-space: pre-wrap;
    white-space: pre-line;
    word-wrap: break-word;
}



