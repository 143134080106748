// Variables

// Colours
$white: #ffffff;
$black: #000000;
$blue: #333366;
$bluetrans: rgba(51,51,102,0.8);


// Animation / Interpolation
$ease: cubic-bezier(0.64, 0.04, 0.35, 1);
$trans: all #{$ease} 0.3s;
