.button {
	display: inline-block;
	position: relative;
	color: $white;
	font-size: 15px;
	background-color: $black;
	border: 1px solid $black;
	padding: 10px 25px 9px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	font-weight: 800;
	&:hover {
		background-color: $white;
		color: $black;
	}
	&.button-small {
		font-size: 12px;
		padding: 8px 10px 7px;
		@media (max-width: 680px) {
			padding: 8px 18px 7px;
		}
	}
}
